import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import LoginForm from './LoginForm';

const Login = ()=>{
    return(
        <React.Fragment>
            <div className='App'>
            <Container fluid className="vh-100 d-flex align-items-center justify-content-center gx-0">
                <Row className="w-100 gx-0" style={{ height: '100%' }}>
                    {/* <Col md="8" className="p-0 black-background d-flex flex-column justify-content-between">
                        <div className="cover-images w-100 d-flex justify-content-center align-items-center flex-grow-1a">
                            <img src="/logo-black-bg.png" alt="Cover" className="img-fluid h-100" />
                        </div>
                        <div className='footer d-flex justify-content-between w-100 p-3'>
                            <div><p className='text-yellow-brand-primary m-0'>Brandbox Co. LTD</p></div>
                            <div><p className='text-yellow-brand-primary m-0'>Copyright © 2024</p></div>
                        </div>
                    </Col> */}
                    <Col md="8" className="p-0 black-background d-flex align-items-center">
                        <div className="cover-images w-100 d-flex justify-content-center h-100-">
                            <img src="/logo-black-bg.png" alt="Cover" className="img-fluid h-100" />
                        </div>
                    </Col>
                    <Col md="4" className="p-0 yellow-background d-flex align-items-center justify-content-center">
                        <LoginForm />
                    </Col>
                </Row>
            </Container>
            </div>
        </React.Fragment>
    )
}

export default Login;