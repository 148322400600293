import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GlobalStore } from '../../StateStores';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { UserLogout } from '../../Actions/Users/AuthActs';
import { AccessLocalUserData } from '../../Helpers/LocalService';

const ProfileDropdown = ()=>{
    const [userData,setUserData] = useState({});
    const [profilePicture,setProfilePicture] = useState('');
    const {UsersDispatch,UsersState} = useContext(GlobalStore);
    const navigate = useNavigate();

    useEffect(()=>{
        async function fetchLocalData(){
            let user = await AccessLocalUserData();
            if(user){
                let parseData = JSON.parse(user);
                setUserData(parseData);
            }
        }
        fetchLocalData();
    },[])

    const {fname,mname,lname,userid,rolename} = userData;

    const handleLogOut = ()=> {
        UserLogout(UsersDispatch);
    }

    const getInitials = (name) => {
        if (!name) return '';
        const names = name.split(' ');
        return names.map(n => n[0]).join('');
    };

    const displayContent = profilePicture ? (
        <img src={profilePicture} alt="Profile" className="profile-picture" />
    ) : (
        <div className="
            hr-profile-initials 
            text-uppercase
            bg-lights 
            boxshadow 
            yellow-background 
            text-black-brand-primary ms-1">{`${getInitials(fname)} ${getInitials(lname)}`}</div>
    );


    return(
        <React.Fragment>
            <div className='d-flex justify-content-between'>
                <div>
                    <small className='text-yellow-brand-primary text-capitalize'>{`${fname} ${mname} ${lname}`}</small>
                </div>
                <div>
                    <UncontrolledDropdown>
                        <DropdownToggle nav className="btn btn-soft-secondary btn-sm">
                            {displayContent}
                        </DropdownToggle>
                        <DropdownMenu end className='yellow-background rounded-0'>
                            <DropdownItem onClick={handleLogOut}>
                                <span><FontAwesomeIcon icon={faPowerOff}/></span>
                                <span className='ms-1'>Logout</span>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProfileDropdown;