import { 
    CLEAR_AUTH_STATE, 
    CLEAR_POST_USERS_BULK_UPLOAD_STATE, 
    GET_SEARCH_USER_FAIL, 
    GET_SEARCH_USER_LOADING, 
    GET_SEARCH_USER_SUCCESS, 
    GET_USERS_FAIL, 
    GET_USERS_LOADING, 
    GET_USERS_SUCCESS, 
    GET_USER_GROUPS_FAIL, 
    GET_USER_GROUPS_LOADING, 
    GET_USER_GROUPS_SUCCESS, 
    GET_USER_GROUP_RIGHTS_FAIL, 
    GET_USER_GROUP_RIGHTS_LOADING, 
    GET_USER_GROUP_RIGHTS_SUCCESS, 
    LOGIN_FAIL, 
    LOGIN_LOADING, 
    LOGIN_SUCCESS, 
    LOGOUT_USER, 
    POST_USERS_BULK_UPLOAD_FAIL, 
    POST_USERS_BULK_UPLOAD_LOADING, 
    POST_USERS_BULK_UPLOAD_SUCCESS, 
    POST_USER_FAIL, 
    POST_USER_GROUP_RIGHTS_FAIL, 
    POST_USER_GROUP_RIGHTS_LOADING,
    POST_USER_GROUP_RIGHTS_SUCCESS,
    POST_USER_LOADING,
    POST_USER_SUCCESS} from "../Constants/ActionTypes";

const Users = (state,{type,payload}) => {

    switch (type) {
        case LOGIN_LOADING:
          return{
              ...state,
              authState:{
                ...state.authState,
                loading:true,
            }
          };
        case LOGIN_SUCCESS:
            return{
                ...state,
                authState:{
                    ...state.authState,
                    loading:false,
                    data:payload,
                    isLoggedIn:true,
                }
            };
        case LOGIN_FAIL:
            return{
                ...state,
                authState:{
                    ...state.authState,
                    loading:false,
                    error:payload
                }
            };
        case LOGOUT_USER:
            return{
                ...state,
                authState:{
                    ...state.authState,
                    loading:false,
                    data:null,
                    isLoggedIn:false,
                }
            };

        case CLEAR_AUTH_STATE:
            return {
                ...state,
                authState:{
                    ...state.authState,
                    loading:false,
                    data:null,
                    isLoggedIn:false,
                    error:null
                }
            };

    case GET_SEARCH_USER_LOADING:
        return{
            ...state,
            searchUsersState:{
            ...state.searchUsersState,
            loading:true,
        }
        };
    case GET_SEARCH_USER_SUCCESS:
        return{
            ...state,
            searchUsersState:{
                ...state.searchUsersState,
                loading:false,
                data:payload,
            }
        };
    case GET_SEARCH_USER_FAIL:
        return{
            ...state,
            searchUsersState:{
                ...state.searchUsersState,
                loading:false,
                error:payload
            }
        };

    case GET_USERS_LOADING:
        return{
            ...state,
            getUsersStates:{
            ...state.getUsersStates,
            loading:true,
        }
        };
    case GET_USERS_SUCCESS:
        return{
            ...state,
            getUsersStates:{
                ...state.getUsersStates,
                loading:false,
                data:payload,
            }
        };
    case GET_USERS_FAIL:
        return{
            ...state,
            getUsersStates:{
                ...state.getUsersStates,
                loading:false,
                error:payload
            }
        };

    case POST_USER_LOADING:
        return{
                ...state,
                postUserStates:{
                ...state.postUserStates,
                loading:true,
            }
        };
    case POST_USER_SUCCESS:
        return{
            ...state,
            postUserStates:{
                ...state.postUserStates,
                loading:false,
                data:payload,
            },
            getUsersStates:{
                ...state.getUsersStates,
                loading:false,
                data:state.getUsersStates.data? [payload,...state.getUsersStates.data]:[payload]
            }
        };
    case POST_USER_FAIL:
        return{
            ...state,
            postUserStates:{
                ...state.postUserStates,
                loading:false,
                error:payload
            }
        };
    case GET_USER_GROUPS_LOADING:
        return{
            ...state,
            getUserGroupsStates:{
            ...state.getUserGroupsStates,
            loading:true,
        }
        };
    case GET_USER_GROUPS_SUCCESS:
        return{
            ...state,
            getUserGroupsStates:{
                ...state.getUserGroupsStates,
                loading:false,
                data:payload,
            }
        };
    case GET_USER_GROUPS_FAIL:
        return{
            ...state,
            getUserGroupsStates:{
                ...state.getUserGroupsStates,
                loading:false,
                error:payload
            }
        };
    case GET_USER_GROUP_RIGHTS_LOADING:
        return{
            ...state,
            getUserGroupsRightStates:{
            ...state.getUserGroupsRightStates,
            loading:true,
        }
        };
    case GET_USER_GROUP_RIGHTS_SUCCESS:
        return{
            ...state,
            getUserGroupsRightStates:{
                ...state.getUserGroupsRightStates,
                loading:false,
                data:payload,
            }
        };
    case GET_USER_GROUP_RIGHTS_FAIL:
        return{
            ...state,
            getUserGroupsRightStates:{
                ...state.getUserGroupsRightStates,
                loading:false,
                error:payload
            }
        };
    case POST_USER_GROUP_RIGHTS_LOADING:
        return{
            ...state,
            postUserGroupsStates:{
            ...state.postUserGroupsStates,
            loading:true,
        }
        };
    case POST_USER_GROUP_RIGHTS_SUCCESS:
         // Check if the role already exists in the current state
        const existingRoleIndex = state.getUserGroupsStates.data
        ? state.getUserGroupsStates.data.findIndex(role => role.id === payload.role_id)
        : -1;

        // If role exists, update the specific role, otherwise add it to the list
        const updatedData = existingRoleIndex !== -1
            ? state.getUserGroupsStates.data.map((role, index) =>
                index === existingRoleIndex ? payload : role
            )
            : [payload, ...state.getUserGroupsStates.data];

        return{
            ...state,
            postUserGroupsStates: {
                ...state.postUserGroupsStates,
                loading: false,
                data: payload,
            },
            getUserGroupsStates: {
                ...state.getUserGroupsStates,
                loading: false,
                data: updatedData,
            }
        };
    case POST_USER_GROUP_RIGHTS_FAIL:
        return{
            ...state,
            postUserGroupsStates:{
                ...state.postUserGroupsStates,
                loading:false,
                error:payload
            }
        };
    case POST_USERS_BULK_UPLOAD_LOADING:
        return{
            ...state,
            postBulkUserUploadState:{
            ...state.postBulkUserUploadState,
            loading:true,
        }
        };
    case POST_USERS_BULK_UPLOAD_SUCCESS:
        return{
            ...state,
            postBulkUserUploadState:{
                ...state.postBulkUserUploadState,
                loading:false,
                data:payload,
            }
        };
    case POST_USERS_BULK_UPLOAD_FAIL:
        return{
            ...state,
            postBulkUserUploadState:{
                ...state.postBulkUserUploadState,
                loading:false,
                error:payload
            }
        };
    case CLEAR_POST_USERS_BULK_UPLOAD_STATE:
        return {
            ...state,
            postBulkUserUploadState:{
                ...state.postBulkUserUploadState,
                loading:false,
                data:null,
                error:null
            }
        };

        default:
           return false;
    }

}

export default Users;